import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { encode64, decode64, processToken } from "@/utils/index.js";

export default new Vuex.Store({
	state: {
		nombreEmpresa: "",
		token: localStorage.getItem("token") || null,
		datosTitular: localStorage.getItem("datosTitular") || null,
		tipoCliente: localStorage.getItem("tipoCliente") || null,
		lang: localStorage.getItem("lang") || "es",
	},
	getters: {
		getToken: (state) => state.token,
		isLoggedIn: (state) => !!state.token,
		getDatosEmpresa: (state) =>
			!!state.datosEmpresa ? processToken(state.datosEmpresa) : null,
		getNombreEmpresa: (state) => state.nombreEmpresa || "",
		getJwtEmpresa: (state) => state.jwtEmpresa,
	},
	mutations: {
		login(state, token) {
			return new Promise((resolve) => {
				localStorage.setItem("token", token);
				state.token = token;
				resolve();
			});
		},
		datosEmpresa(state, datosEmpresa) {
			return new Promise((resolve) => {
				localStorage.setItem("datosEmpresa", datosEmpresa);
				state.datosEmpresa = datosEmpresa;
				resolve();
			});
		},
		logout(state) {
			return new Promise((resolve) => {
				state.token = null;
				state.datosEmpresa = null;
				state.jwtEmpresa = null;
				resolve();
			});
		},
		setNombreEmpresa(state, domain) {
			return new Promise((resolve) => {
				state.nombreEmpresa = domain;
				resolve();
			});
		},
		jwtEmpresa(state, token) {
			return new Promise((resolve) => {
				localStorage.setItem("jwtEmpresa", token);
				state.jwtEmpresa = token;
				resolve();
			});
		},
	},
	actions: {
		login({ commit, getters }, { user, pass }) {
			return new Promise(async (resolve, reject) => {
				try {
					let { data: AUTH_TOKEN } = await axios({
						method: "POST",
						url: `${process.env.VUE_APP_API_URL}/login.php`,
						data: {
							usuario: user,
							password: pass,
						},
					});

					let empresa = JSON.parse(decode64(AUTH_TOKEN.token.split(".")[1]))
						.empresa;
					if (empresa != getters.getNombreEmpresa) {
						await commit("logout");
						reject();
						return;
					}

					await Promise.all([commit("login", AUTH_TOKEN.token)]);

					resolve();
				} catch (e) {
					console.log(e);
					await commit("logout");
					reject();
					return;
				}
			});
		},
		logout({ commit }) {
			return new Promise((resolve, reject) => {
				localStorage.removeItem("token");
				localStorage.removeItem("datosEmpresa");
				localStorage.removeItem("jwtEmpresa");
				commit("logout");
				resolve();
			});
		},
		setNombreEmpresa({ commit }, domain) {
			return new Promise(async (resolve) => {
				await commit("setNombreEmpresa", domain);
				resolve();
			});
		},
	},
	modules: {},
});
