<template>
	<v-app>
		<v-app-bar app color="primary" v-if="$store.getters.isLoggedIn">
			<img
				height="100%"
				contain
				:src="`./img/logo_blanco_${$vuetify.theme.themes.name}.png`"
				class="mr-4"
				v-show="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
			/>
			<v-tabs
				icons-and-text
				optional
				slider-color="white"
				slider-size="3"
				style="flex: unset !important; width: unset"
			>
				<v-tab style="min-width: 40px" class="white--text" text to="/">
					<span v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
						>Inicio</span
					>
					<v-icon color="white" v-text="'mdi-home'" />
				</v-tab>
				<v-tab
					style="min-width: 40px"
					class="white--text"
					text
					to="/incidencias"
				>
					<span v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
						>Incidencias</span
					>
					<v-icon color="white" v-text="'mdi-alert-circle'" />
				</v-tab>
				<v-tab style="min-width: 40px" class="white--text" text to="/clientes">
					<span v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
						>Clientes</span
					>
					<v-icon color="white" v-text="'mdi-account-group'" />
				</v-tab>
			</v-tabs>

			<v-spacer />

			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-on="on" v-bind="attrs" class="mr-2" color="white" icon>
					<v-badge
        color="error"
        overlap
				:content="incidenciasAbiertasHoy"
				:value="incidenciasAbiertasHoy"
      >
						<v-icon>mdi-bell</v-icon>
					</v-badge>
					</v-btn>
				</template>
				<v-list dense>
					<v-list-item two-line to="/incidencias">
						<v-list-item-content>
							<v-list-item-title>
								Incidencias de hoy abiertas
							</v-list-item-title>
							<v-list-item-subtitle>
								{{ incidenciasAbiertasHoy }}
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>

			<v-btn to="/logout" color="error" :elevation="0">
				<v-icon left>mdi-logout</v-icon>
				Cerrar sesión
			</v-btn>
		</v-app-bar>

		<v-main>
			<router-view :key="$router.fullPath" />
		</v-main>

		<v-speed-dial bottom right fixed v-if="development && themeLoaded">
			<template v-slot:activator>
				<v-btn small color="primary" dark fab>
					<v-icon v-text="'mdi-palette'" />
				</v-btn>
			</template>
			<v-btn
				v-for="theme in themes.filter(({ name }) => name != theme)"
				:key="theme.name"
				:color="theme.light.primary"
				@click="setTheme(theme.name)"
				dark
				fab
				small
				v-text="theme.name[0]"
			/>
		</v-speed-dial>

		<v-footer :class="`${$route.fullPath == '/alta_nueva' ? 'd-none' : ''}`">
			<v-row class="mt-3">
				<v-col class="text-center">
					<v-btn small text :href="`mailto:${footer.email}`">
						<v-icon left>mdi-email-outline</v-icon>
						{{ footer.email }}
					</v-btn>
					<v-btn
						small
						text
						:href="`tel:${footer.telefono.replaceAll(/ /g, '')}`"
					>
						<v-icon left>mdi-phone</v-icon>
						{{ footer.telefono }}
					</v-btn>
				</v-col>
			</v-row>
		</v-footer>
	</v-app>
</template>

<script>
export default {
	name: "App",

	data: () => ({
		menuShow: true,
		msg: "",
		snack: false,
		development: true, //process.env.NODE_ENV == "development",
		theme: null,
		themes: [],
		themeLoaded: false,
		footer: {
			email: "",
			telefono: "",
		},

		incidenciasAbiertasHoy: 0,
	}),
	metaInfo() {
		return {
			title: "Gestión de Incidencias | " + this.$store.getters.getNombreEmpresa,
			link: [
				{
					rel: "icon",
					href: `./img/fav_${this.$store.getters.getNombreEmpresa}.png`,
				},
			],
		};
	},
	async beforeCreate() {
		let dominio =
			window.location.host.replaceAll(/www./g, "").split(".")[1] ||
			"alumbraenergia";
		await this.$store.dispatch("setNombreEmpresa", dominio);
		let { data: themes } = await axios({ url: "/themes.json" });

		if (this.development) this.themes = Object.values(themes.themes);
		let theme = themes.themes[dominio];
		this.theme = theme.name;
		this.footer = theme.footer;
		Object.keys(theme.light).forEach((key) => {
			this.$vuetify.theme.themes.light[key] = theme.light[key];
		});
		this.$vuetify.theme.themes.name = theme.name;
		this.themeLoaded = true;
	},
	methods: {
		setTheme(theme) {
			return new Promise(async (resolve) => {
				if (!this.development) return;
				this.themeLoaded = false;
				if (typeof theme != "object")
					theme = this.themes.find(({ name }) => name == theme);
				this.theme = theme.name;
				this.footer = theme.footer;
				Object.keys(theme.light).forEach((key) => {
					this.$vuetify.theme.themes.light[key] = theme.light[key];
				});
				this.$vuetify.theme.themes.name = theme.name;
				this.themeLoaded = true;
				await this.$store.dispatch("setNombreEmpresa", theme.name);
				resolve();
			});
		},

		getResumen() {
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_API_URL}/incidencias.php`,
				params: {
					token: this.$store.getters.getToken,
				},
			}).then(({ data }) => {
				data = data.filter(
					(i) =>
						new Date().setHours(0, 0, 0, 0) ==
						new Date(Number(i.fecha)).setHours(0, 0, 0, 0)
				);

				this.incidenciasAbiertasHoy = data.filter(
					(i) => i.estado == "Abierta"
				).length;
			});
		},
	},
	mounted() {
		this.$root.$on("snack", (msg) => {
			this.snack = true;
			this.msg = msg;
		});

		this.$root.$on("getAlerts", () => {
			this.getResumen();
		})

			this.getResumen();
	},
};
</script>z< 
