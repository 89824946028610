export function encode64(data) {
	return btoa(data);
}
export function decode64(data) {
	return new TextDecoder().decode(urlB64ToUint8Array(data));
}

function urlB64ToUint8Array(base64String) {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i)
		outputArray[i] = rawData.charCodeAt(i);

	return outputArray;
}

export function processToken(token) {
	let temp = JSON.parse(decode64(token.split(".")[1]));

	try {
		temp.iss = JSON.parse(temp.iss);
	} catch (e) {
		// pass
	}
	return temp;
}

export function parseDate(date, timestamp = false, mes = true) {
	if (date == null || date == undefined || date == "") return "";
	let fecha;

	if (!isNaN(Number(date))) fecha = new Date(Number(date));
	else
		fecha = new Date(
			String(date)
				.replaceAll(/\\/g, "")
				.split(" ")[0]
		);

	fecha.setHours(0, 0, 0, 0);

	if (timestamp) return fecha.getTime();

	const MESES = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	let day = `0${fecha.getDate()}`.slice(-2);
	let month = mes
		? MESES[fecha.getMonth()]
		: `0${fecha.getMonth() + 1}`.slice(-2);
	let year = fecha.getFullYear();

	return [day, month, year].join(" ");
}

export function getSituacionIcon(situacion) {
	switch (situacion) {
		case "Trámite":
			return { color: "secondary", icon: "mdi-timer-sand" };
		case "Sin enviar":
			return { color: "#6403ff", icon: "mdi-email-remove-outline" };
		case "Pendiente de Firma":
			return { color: "#577399", icon: "mdi-draw" };
		case "Anulado":
		case "KO Scoring":
		case "NEGACION":
			return { color: "#d00000", icon: "mdi-close-octagon-outline" };
		case "Prueba ":
			return { color: "purple", icon: "mdi-test-tube" };
		case "Activado":
			return { color: "success", icon: "mdi-check" };
		case "Incidencia":
			return { color: "#ff4000", icon: "mdi-alert-circle-outline" };
		case "Borrador":
			return { color: "primary", icon: "mdi-eraser" };
		case "Pendiente Activación a Futuro":
			return { color: "#ffa600", icon: "mdi-clock-time-five-outline" };
		case "Baja":
		case "Baja por modificación":
			return { color: "#FF3C00", icon: "mdi-pencil-off" };
		case "Caducado":
			return { color: "#FF3C00", icon: "mdi-timer-sand-empty" };
		case "Cortado":
			return { color: "#FF3C00", icon: "mdi-content-cut" };
		case "Firmado":
			return { color: "primary", icon: "mdi-draw" };
		case "Inspección Alta":
			return { color: "warning", icon: "mdi-account-tie" };
		case "pagada":
			return { color: "success", icon: "mdi-check" };
		case "pendiente pago":
			return { color: "warning", icon: "mdi-timer-sand" };
		default:
			return { color: "#a3a3a3", icon: "mdi-circle" };
	}
}

export function calcularConsumoAnual(consumos, cups, tarifa) {
	let datos = consumos.filter(({ CodigoCUPS }) => cups.includes(CodigoCUPS));

	let a1 = 0;
	let a2 = 0;
	let a3 = 0;
	let a4 = 0;
	let a5 = 0;
	let a6 = 0;

	let ultimaFechaConsumo = new Date(datos[datos.length - 1].FechaFin);

	ultimaFechaConsumo =
		(ultimaFechaConsumo.getFullYear() +
			"-" +
			(ultimaFechaConsumo.getMonth() + 1) +
			"-" +
			ultimaFechaConsumo.getDate(),
		datos[datos.length - 1].FechaFin.split("T"));

	ultimaFechaConsumo = new Date(ultimaFechaConsumo[0]);

	ultimaFechaConsumo = `${ultimaFechaConsumo.getFullYear() - 1}-${(
		"0" +
		ultimaFechaConsumo.getMonth() +
		1
	).slice(-2)}-${("0" + ultimaFechaConsumo.getDate()).slice(-2)}T00:00:00`;

	let total = 0;
	datos
		.filter(
			(consumo) =>
				new Date(consumo.FechaFin).getTime() >=
				new Date(ultimaFechaConsumo).getTime()
		)
		.forEach(({ Activa1, Activa2, Activa3, Activa4, Activa5, Activa6 }) => {
			total += Activa1;
			total += Activa2;
			total += Activa3;
			total += Activa4;
			total += Activa5;
			total += Activa6;

			a1 += Activa1 || 0;
			a2 += Activa2 || 0;
			a3 += Activa3 || 0;
			a4 += Activa4 || 0;
			a5 += Activa5 || 0;
			a6 += Activa6 || 0;
		});

	let finUltimoConsumo = new Date(datos[datos.length - 1].FechaFin).getTime();
	let aux = datos.filter(
		(consumo) =>
			new Date(consumo.FechaFin).getTime() >=
			new Date(ultimaFechaConsumo).getTime()
	);

	if (aux.length == 0) {
		return {
			a1,
			a2,
			a3,
			a4,
			a5,
			a6,
			total,
		};
	}

	let inicioUltimoConsumo = new Date(aux[0].FechaInicio).getTime();

	let Y = (finUltimoConsumo - inicioUltimoConsumo) / 864e5;

	a1 = Math.round((a1 / Y) * 365);
	a2 = Math.round((a2 / Y) * 365);
	a3 = Math.round((a3 / Y) * 365);
	a4 = Math.round((a4 / Y) * 365);
	a5 = Math.round((a5 / Y) * 365);
	a6 = Math.round((a6 / Y) * 365);
	total = Math.round((total / Y) * 365);

	var codTarifa = tarifa;
	("003" != codTarifa && "011" != codTarifa) ||
		((a1 = a1 + a4),
		(a2 = a2 + a5),
		(a3 = a3 + a6),
		(a4 = "0"),
		(a5 = "0"),
		(a6 = "0"));

	return {
		a1,
		a2,
		a3,
		a4,
		a5,
		a6,
		total,
	};

	// console.log(total);
}

export function obtenerNombreTarifaML(codTarifa) {
	switch (codTarifa) {
		case "001":
			return "2.0A ML";
		case "003":
			return "3.0A ML";
		case "004":
			return "2.0DHA ML";
		case "005":
			return "2.1A ML";
		case "006":
			return "2.1DHA ML";
		case "007":
			return "2.0DHS";
		case "008":
			return "2.1DHS ML";
		case "011":
			return "3.1A ML";
		case "012":
			return "6.1A ML";
		case "013":
			return "6.2 ML";
		case "014":
			return "6.3 ML";
		case "015":
			return "6.4 ML";
		case "016":
			return "6.5 ML";
		case "017":
			return "6.1B ML";
		default:
			return "";
	}
}

export function obtenerNombreTarifa(codTarifa) {
	return obtenerNombreTarifaML(codTarifa).replaceAll(/ ML/g, "");
}

export function getIdTarifa(tarifa) {
	switch (tarifa) {
		case "2.0A ML":
			return 20020;
		case "2.0DHA ML":
			return 20021;
		case "2.0DHS":
			return 20024;
		case "2.1A ML":
			return 20022;
		case "2.1DHA ML":
			return 20023;
		case "2.1DHS ML":
			return 20025;
		case "3.0A ML":
			return 20030;
		case "3.1A ML":
			return 20031;
		case "6.1A ML":
			return 20061;
		case "6.1B ML":
			return 20063;
		default:
			return "";
	}
}

export const perColumnFilter = (value, filter, dataType = "text") => {
	if (filter == "" || filter == null) return true;
	if (value == "" || value == null) return false;

	const filterText = (filter, value) => {
		value = String(value)
			.toLowerCase()
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "");
		filter = filter
			.toLowerCase()
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "");
		const isOperation = /=|<|>|!/.test(filter[0]);
		if (isOperation) {
			switch (filter[0]) {
				case "!":
					return !value.includes(filter.substring(1));
				case "=":
					return value == filter.substring(1);
				case ">":
					return Number(value) >= Number(filter.substring(1));
				case "<":
					return Number(value) <= Number(filter.substring(1));
				default:
					return false;
			}
		} else {
			return value.includes(filter);
		}
	};
	const filterDate = (filter, value) => {
		filter = filter.map((f) => {
			let temp = new Date(f);
			temp.setHours(0, 0, 0, 0);
			return temp.getTime();
		});
		value = parseDate(value, true);
		let max = Math.max(...filter);
		let min = Math.min(...filter);
		return max >= value && value >= min;
	};
	const filterSelect = (filter, value) => {
		if (filter.length > 1) return filter.includes(value);
		else return filterText(filter[0], value);
	};

	switch (dataType) {
		case "select":
			return filterSelect(filter, value);
		case "date":
			return filterDate(filter, value);
		case "text":
		default:
			return filterText(filter, value);
	}
};
