import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("@/views/Home.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/Login.vue"),
		beforeEnter: (to, from, next) => {
			if (store.getters.isLoggedIn) next({ name: "Home" });
			else next();
		},
	},
	{
		path: "/logout",
		name: "Logout",
		beforeEnter: async (to, from, next) => {
			if (confirm("¿Estás seguro de que quieres cerrar sesión?")) {
				await store.dispatch("logout");
				router.push("/login");
			}
		},
	},
	{
		path: "/clientes",
		name: "Clientes",
		component: () => import("@/views/Clientes.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/incidencias",
		name: "Incidencias",
		component: () => import("@/views/Incidencias.vue"),
		meta: { requiresAuth: true },
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

import { decode64 } from "@/utils/index.js";

const validarToken = (token) =>
	(JSON.parse(decode64(token.split(".")[1])).exp * 1000) > new Date().getTime();

router.beforeEach(async (to, from, next) => {
	if (to.matched.some((record) => record.meta.soloLocal)) {
		if (process.env.NODE_ENV == "development") next();
		else next({ name: "Home" });
	}
	if (
		to.matched.some((record) => record.meta.requiresAuth) &&
		(store.getters.getToken === null || !validarToken(store.getters.getToken))
	) {
		let msg = store.getters.getToken != null ? "La sesión ha expirado" : "";
		await store.dispatch("logout");
		next({ name: "Login", query: { redirect: to.path, msg } });
	}
	next();
});

export default router;
