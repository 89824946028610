import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.config.errorHandler = async (error) => {
	if (
		store.getters.isLoggedIn &&
		error.response &&
		(error.response.status == 401 || error.response.status == 403)
	) {
		await store.dispatch("logout");
		router.push({
			name: "Login",
			query: {
				msg: "La sesión ha expirado",
				redirect: router.app._route.fullPath,
			},
		});
	} else {
		console.error(error);
	}
};

import axios from "axios";
window.axios = axios.create({
	headers: { Authorization: store.getters.getToken },
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
