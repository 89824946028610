var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$store.getters.isLoggedIn)?_c('v-app-bar',{attrs:{"app":"","color":"primary"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.sm && !_vm.$vuetify.breakpoint.xs),expression:"!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"}],staticClass:"mr-4",attrs:{"height":"100%","contain":"","src":("./img/logo_blanco_" + (_vm.$vuetify.theme.themes.name) + ".png")}}),_c('v-tabs',{staticStyle:{"flex":"unset !important","width":"unset"},attrs:{"icons-and-text":"","optional":"","slider-color":"white","slider-size":"3"}},[_c('v-tab',{staticClass:"white--text",staticStyle:{"min-width":"40px"},attrs:{"text":"","to":"/"}},[(!_vm.$vuetify.breakpoint.sm && !_vm.$vuetify.breakpoint.xs)?_c('span',[_vm._v("Inicio")]):_vm._e(),_c('v-icon',{attrs:{"color":"white"},domProps:{"textContent":_vm._s('mdi-home')}})],1),_c('v-tab',{staticClass:"white--text",staticStyle:{"min-width":"40px"},attrs:{"text":"","to":"/incidencias"}},[(!_vm.$vuetify.breakpoint.sm && !_vm.$vuetify.breakpoint.xs)?_c('span',[_vm._v("Incidencias")]):_vm._e(),_c('v-icon',{attrs:{"color":"white"},domProps:{"textContent":_vm._s('mdi-alert-circle')}})],1),_c('v-tab',{staticClass:"white--text",staticStyle:{"min-width":"40px"},attrs:{"text":"","to":"/clientes"}},[(!_vm.$vuetify.breakpoint.sm && !_vm.$vuetify.breakpoint.xs)?_c('span',[_vm._v("Clientes")]):_vm._e(),_c('v-icon',{attrs:{"color":"white"},domProps:{"textContent":_vm._s('mdi-account-group')}})],1)],1),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"white","icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"error","overlap":"","content":_vm.incidenciasAbiertasHoy,"value":_vm.incidenciasAbiertasHoy}},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}],null,false,2107744404)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"two-line":"","to":"/incidencias"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Incidencias de hoy abiertas ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.incidenciasAbiertasHoy)+" ")])],1)],1)],1)],1),_c('v-btn',{attrs:{"to":"/logout","color":"error","elevation":0}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-logout")]),_vm._v(" Cerrar sesión ")],1)],1):_vm._e(),_c('v-main',[_c('router-view',{key:_vm.$router.fullPath})],1),(_vm.development && _vm.themeLoaded)?_c('v-speed-dial',{attrs:{"bottom":"","right":"","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"primary","dark":"","fab":""}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-palette')}})],1)]},proxy:true}],null,false,3929183472)},_vm._l((_vm.themes.filter(function (ref) {
	var name = ref.name;

	return name != _vm.theme;
})),function(theme){return _c('v-btn',{key:theme.name,attrs:{"color":theme.light.primary,"dark":"","fab":"","small":""},domProps:{"textContent":_vm._s(theme.name[0])},on:{"click":function($event){return _vm.setTheme(theme.name)}}})}),1):_vm._e(),_c('v-footer',{class:("" + (_vm.$route.fullPath == '/alta_nueva' ? 'd-none' : ''))},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"small":"","text":"","href":("mailto:" + (_vm.footer.email))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email-outline")]),_vm._v(" "+_vm._s(_vm.footer.email)+" ")],1),_c('v-btn',{attrs:{"small":"","text":"","href":("tel:" + (_vm.footer.telefono.replaceAll(/ /g, '')))}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(_vm.footer.telefono)+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }