import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { es } from "vuetify/es5/locale";

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: { es },
		current: "es",
	},
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: "#E41270",
				secondary: "#0983BC",
			},
		},
	},
});
